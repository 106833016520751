import {
  // eslint-disable-next-line no-restricted-imports
  Avatar,
  // eslint-disable-next-line no-restricted-imports
  AvatarGroup,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import WarningIcon from '@watershed/icons/components/Warning';
import Callout from '@watershed/shared-frontend/components/Callout';
import Logo from '@watershed/ui-core/components/Logo';

export default function LoginHeader({
  title,
  orgName,
  orgIconUrl,
  error,
}: {
  title: React.ReactNode;
  orgName?: string;
  orgIconUrl?: string;
  error?: string | null;
}) {
  return (
    <>
      <Box
        component="header"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: -4,
          paddingBottom: 2,
          '& .MuiAvatarGroup-avatar': {
            borderRadius: 32,
            width: 64,
            height: 64,
          },
        }}
      >
        <AvatarGroup>
          {orgIconUrl && (
            <Avatar
              sx={{
                backgroundColor: 'white',
                '& img': {
                  objectFit: 'scale-down',
                  height: '66%',
                  width: '66%',
                  borderRadius: 4,
                },
              }}
            >
              <img src={orgIconUrl} alt={orgName} height="48px" width="48px" />
            </Avatar>
          )}
          <Box
            sx={{
              bgcolor: 'background.paper',
              padding: 0.5,
            }}
          >
            <Logo size={56} />
          </Box>
        </AvatarGroup>
      </Box>
      <Typography variant="h1" align="center" marginBottom={3}>
        {title}
      </Typography>
      {error && (
        <Stack marginBottom={2}>
          <Callout
            description={error}
            variant="warning"
            IconComponent={WarningIcon}
          />
        </Stack>
      )}
    </>
  );
}
