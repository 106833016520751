import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import { parseQueryParam } from '@watershed/shared-universal/utils/queryParamUtils';

/**
 * Checks a query object and returns the value of the `redirect` parameter, if
 * it is safe (root-relative). Returns null if there is no `redirect` parameter
 * or it is not safe.
 */
export default function getQueryStringRedirect(
  query: NextParsedUrlQuery
): string | null {
  const redirectPath = parseQueryParam(query.redirect);
  if (
    !redirectPath ||
    redirectPath.startsWith('//') ||
    !redirectPath.startsWith('/')
  ) {
    return null;
  }
  return redirectPath;
}

export function useQueryStringRedirect() {
  const router = useRouter();
  return getQueryStringRedirect(router.query);
}
