import * as Sentry from '@sentry/browser';
import wsFetch from './wsFetch';

export async function submitEmailPassword(email: string, password: string) {
  const formData = new URLSearchParams();
  formData.append('email', email);
  formData.append('password', password);

  const response = await wsFetch('/auth/login', {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
    },
  });
  if (response.redirected) {
    return { redirect: response.url };
  }

  if (!response.ok) {
    let message = 'Unknown error';

    try {
      const json = await response.json();
      message = json.error;
    } catch (err) {
      Sentry.captureException(err);
    }
    throw Error(message);
  }
}

export async function sendMagicLink({
  email,
  redirect,
}: {
  email: string;
  redirect: string | null;
}) {
  const formData = new URLSearchParams();
  formData.append('email', email);
  if (redirect) {
    formData.append('redirect', redirect);
  }

  const response = await wsFetch('/auth/sso/magiclink', {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
    },
  });

  if (!response.ok) {
    let message = 'Unknown error';

    try {
      const json = await response.json();
      message = json.error;
    } catch (err) {
      Sentry.captureException(err);
    }
    throw Error(message);
  }
}

/**
 * Determines whether a user of an org should sign in
 * using SSO rather than a password.
 */
export function shouldUserUseSso(
  userEmail: string,
  passwordAuthDisabledForOrg: boolean,
  orgDomains: Array<string>
) {
  return (
    passwordAuthDisabledForOrg &&
    orgDomains.some((domain) => userEmail.endsWith(`@${domain}`))
  );
}
