export enum AuthError {
  Unknown = 'Unknown',
  InvalidDomain = 'InvalidDomain',
  InvalidConnection = 'InvalidConnection',
  InvalidStrategy = 'InvalidStrategy',
  Unlinked = 'Unlinked',
  MissingCode = 'MissingCode',
  CouldNotCreateUser = 'CouldNotCreateUser',
  AccessDenied = 'AccessDenied',
  AutoProvisionDisabled = 'AutoProvisionDisabled',
  PermissionsRequired = 'PermissionsRequired',
  UserDeleted = 'UserDeleted',
  ProfileNotAllowedOutsideOrganization = 'ProfileNotAllowedOutsideOrganization',
  InvalidGrant = 'InvalidGrant',
}

export type AuthErrorWithContactInfo = {
  authError: AuthError;
  orgAdminContactInfo?: {
    name: string;
    email: string;
  };
};

export const MAGIC_LINK_EXPIRY_MINUTES = 10;

export function getAuthErrorMessage(authError: string): string {
  switch (authError) {
    case AuthError.InvalidDomain:
      return 'SSO is not configured for this domain.';
    case AuthError.InvalidConnection:
    case AuthError.InvalidStrategy:
    case AuthError.Unlinked:
      return 'Invalid SSO configuration.';
    case AuthError.MissingCode:
      return 'Missing SSO code.';
    case AuthError.AccessDenied:
      return `If your login link is over ${MAGIC_LINK_EXPIRY_MINUTES} minutes old or has
      been used already, please request a new one. If this persists, it may
      indicate that your organization’s email system has a security filter
      that pre-clicks links in emails, which may need to be disabled for emails
      sent by Watershed.  
      `;
    case AuthError.AutoProvisionDisabled:
      return 'No account exists for this email address. Were you invited with a different email?';
    case AuthError.PermissionsRequired:
      return 'Permissions have not been granted yet.';
    case AuthError.ProfileNotAllowedOutsideOrganization:
      return 'Your organization’s SSO settings are incorrectly configured. Please reach out to Watershed for support.';
    case AuthError.InvalidGrant:
      return 'Your authentication token either expired or has been used. Please try logging in again.';
    case AuthError.CouldNotCreateUser:
    case AuthError.Unknown:
    default:
      return 'Unknown error occurred. Please try again.';
  }
}

// All demo orgs are given this email domain suffix by default.
export const DEMO_ORG_EMAIL_DOMAIN_SUFFIX = '.democo.watershedclimate.com';
